exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-custom-bubbler-beverage-program-js": () => import("./../../../src/pages/custom-bubbler-beverage-program.js" /* webpackChunkName: "component---src-pages-custom-bubbler-beverage-program-js" */),
  "component---src-pages-decor-inspo-js": () => import("./../../../src/pages/decor-inspo.js" /* webpackChunkName: "component---src-pages-decor-inspo-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-our-story-js": () => import("./../../../src/pages/our-story.js" /* webpackChunkName: "component---src-pages-our-story-js" */),
  "component---src-pages-products-js": () => import("./../../../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */)
}

